<template>
  <div class="form-group">
    <div class="date-picker">
      <button type="button" class="date-picker--icon" @click="previousDay">
        <span class="material-icons">keyboard_arrow_left</span>
      </button>
      <span class="date-picker--selected-date">{{readableDate}}</span>
      <date-picker class="d-flex" :first-day-of-week="2" locale="de" v-model="date" @dayclick="dateChanged" :key="childKey">
        <template v-slot="{ togglePopover }">
          <button type="button" class="date-picker--icon" @click="togglePopover()"><span class="material-icons">date_range</span></button>
        </template>
      </date-picker>
      <button type="button" class="date-picker--icon" @click="nextDay">
        <span class="material-icons">keyboard_arrow_right</span>
      </button>
    </div>
  </div>
</template>

<script>
  import { DatePicker } from 'v-calendar';
  import { getWeekday, getMonth } from '@/utils/appointment-helpers';

  export default {
    name: 'DateSelector',
    components: {
      DatePicker,
    },
    props: {
      inputDate: {
        type: Date,
      },
    },
    data() {
      return {
        date: new Date(),
        childKey: 0,
      }
    },
    watch: {
      inputDate: {
        handler (newVal, oldVal) {
          this.date = newVal;
        }
      }
    },
    computed:{
      readableDate(){
        if(this.date != null){
          var d = this.date == null ? new Date() : new Date(this.date);
          var day = getWeekday(d.getDay()).substring(0, 2);
          var month = getMonth(d.getMonth()+1).substring(0,3);
          var result = day+', '+d.getDate()+'.'+' '+month+' '+d.getFullYear();
          return result;
        }
        return null;
      },
    },
    methods: {
      forceRerender(){
      this.childKey += 1;
      },
      previousDay(){
        var startDate = new Date(this.date);
        // seconds * minutes * hours * milliseconds = 1 day 
        var day = 60 * 60 * 24 * 1000;
        this.date = new Date(startDate.getTime() - day);
        this.dateChanged();
      },
      nextDay(){
        var startDate = new Date(this.date);
        // seconds * minutes * hours * milliseconds = 1 day 
        var day = 60 * 60 * 24 * 1000;
        this.date = new Date(startDate.getTime() + day);
        this.dateChanged();
      },
      dateChanged(){
        this.forceRerender();
        this.$emit('dateChanged',this.date);
      }
    }
  }
</script>
<style lang="scss" scoped>

  .form-group .date-picker, .form-group .time-picker {
    display: inline-flex;
    width: 100%;
    vertical-align: middle;
    justify-content: space-between;
    background-color: #FFFFFF;
    height: 42px;
    border-radius: 0.25rem;
    max-width: 300px;
    margin: 0 auto;
  }

</style>